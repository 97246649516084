import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/SEO";
import Hero from "../components/index/hero";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import ScheduleData from "../data/schedule.json";
import { FaDiscord } from "react-icons/fa";
import Partners from "../components/partners";
import Banner from "../components/banner";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useState } from "react";

const Ticker = ({ num, suffix }) => {
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <CountUp
      end={num}
      suffix={suffix}
      duration={1.25}
      delay={0.25}
      separator=","
      start={viewPortEntered ? null : 0}
    >
      {({ countUpRef }) => {
        return (
          <VisibilitySensor
            active={!viewPortEntered}
            onChange={(isVisible) => {
              if (isVisible) {
                setViewPortEntered(true);
              }
            }}
            delayedCall
          >
            <h4 className="text-xl sm:text-2xl lg:text-3xl" ref={countUpRef} />
          </VisibilitySensor>
        );
      }}
    </CountUp>
  );
};

const IndexPage = () => {
  const { heroImage, site } = useStaticQuery(graphql`
    query indexPageQuery {
      heroImage: file(relativePath: { eq: "index.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          email
          canRegister
          registerLink
          mailLink
        }
      }
    }
  `);
  const { email, canRegister, registerLink, mailLink } = site.siteMetadata;

  const importantLinks = [
    {
      text: "Have any questions?",
      linkText: `Feel free to email us.`,
      url: `mailto:${email}`,
    },
    {
      text: "Interested in joining the IBB team (outreach, content, technology)?",
      linkText: `Reach out to us at internationalbiologybowl@gmail.com.`,
      url: `mailto:internationalbiologybowl@gmail.com`,
    },
  ];

  return (
    <Layout>
      <SEO />
      <Hero
        image={heroImage}
        title="International Biology Bowl"
        tagline="The first organization dedicated to fostering interest in biology among high-school students through competition and collaboration."
        button={{
          link: canRegister ? registerLink : mailLink,
          label: canRegister ? "Register" : "Join Mailing List",
        }}
      />
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6">
        <div className="flex flex-wrap overflow-hidden">
          <div
            style={{ marginBottom: "24px" }}
            className="my-3 px-3 w-full md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2"
          >
            <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
              The <span className="">Competition</span>
            </h1>
            <p className="mb-4 text-gray-500">
              The International Biology Bowl (IBB) is a unique, 501(c)(3)
              non-profit and PVSA-certifying organization dedicated to fostering
              a deep interest in the biological sciences in students around the
              globe, regardless of location or income. The IBB creates many
              competitions, all of which are multi-tiered, hosted virtually and
              without cost. We currently run multiple regional competitions
              around the world which have accumulated thousands of students. We
              will have our 2023-24 regional tournaments beginning October 2023,
              and our 2023-24 International Tournament in February 2024.{" "}
            </p>
            <br />
            <Link
              to="/competition/"
              className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out"
            >
              Learn More
            </Link>
            <br />
          </div>
          <div className="my-3 px-3 w-full overflow-hidden md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 md:flex">
            <StaticImage
              className="rounded-lg md:my-auto w-100 shadow-lg"
              src="../images/biology.png"
              alt="Biology"
              placeholder="blurred"
            />
          </div>
        </div>
      </div>

      <div className="bg-blue-500">
        <div className="max-w-screen-xl mx-auto py-10 pt-16 px-4 sm:px-6">
          <div className="flex flex-col sm:flex-row justify-center space-x-4 md:space-x-12 lg:space-x-24 font-bold mb-6 text-white mx-auto w-full text-center">
            <Ticker num={4000} suffix="+ Participants" />
            <Ticker num={50} suffix="+ Chapters" />
            <Ticker num={25} suffix="+ Countries" />
          </div>
        </div>
      </div>
      <div className="bg-gray-800">
        <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6">
          <div className="flex flex-wrap overflow-hidden">
            {" "}
            {/* -mx-3 sm:-mx-3 md:-mx-3 lg:-mx-3 xl:-mx-3 */}
            <div className="my-3 px-3 w-full md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2">
              <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-white sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
                Schedule
              </h1>
              <div>
                {ScheduleData.map((date, i) => {
                  return (
                    <div
                      className={
                        i === ScheduleData.length - 1
                          ? `text-gray-300`
                          : "text-gray-300 mb-6"
                      }
                    >
                      <p className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gradient-to-r from-blue-500 to-blue-700 text-white mb-3">
                        {date.day}
                      </p>
                      {date.events.map((event) => {
                        return (
                          // <p>{`${event.time} - ${event.name}`}</p>
                          <p className="leading-6">
                            <span className="font-bold">{event.time}</span>
                            {event.name && `: ${event.name}`}
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
                {/* <p className='text-gray-300 text-sm mt-3'>Note: dates are tentative</p> */}
              </div>
            </div>
            <div
              style={{ marginBottom: "24px" }}
              className="my-3 px-3 w-full md:my-3 md:px-3 md:w-1/2 lg:my-3 lg:px-3 lg:w-1/2 xl:my-3 xl:px-3 xl:w-1/2 block"
            >
              <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-white sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
                Important Links
              </h1>
              <div className="text-gray-300 mb-10">
                {importantLinks.map((link) => {
                  return (
                    <p className="leading-6">
                      {`${link.text} `}
                      <a
                        href={link.url}
                        className="font-bold text-blue-300 hover:text-blue-400 no-underline"
                        target="_blank"
                      >
                        {link.linkText}
                      </a>
                    </p>
                  );
                })}
              </div>
              <div
                style={{ maxWidth: "250px" }}
                className="index-buttons block"
              >
                {/* <div className="mb-4">
                  <a href="https://docs.google.com/document/d/13obJFiOo2Qv69VxmUKF6koGIyl2ML_wY5ikFotVWEts/edit" target="_blank" className="flex justify-center align-center items-center text-blue-700 text-center bg-blue-100 hover:bg-blue-200 focus:outline-none focus:bg-blue-200 px-5 py-3 border border-transparent font-medium rounded-md transition duration-150 ease-in-out">Rules and Logistics</a>
                </div> */}
                <div>
                  <a
                    href="https://discord.gg/sFETzRbjMR"
                    target="_blank"
                    className="justify-center align-center items-center discord-btn focus:outline-none px-5 py-3 border flex border-transparent font-medium rounded-md text-white transition duration-150 ease-in-out"
                  >
                    <div>
                      <FaDiscord className="inline text-xl mr-1" /> Join the
                      Discord Server
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Partners />
      <Banner isWhite={true} />
    </Layout>
  );
};

export default IndexPage;
